import React from "react";

const Copyright = () => {
  return (
    <div className="copyright">
      <p>2023 © tuCalma. Todos los derechos reservados</p>
    </div>
  );
};

export default Copyright;
